/* Gray Scale */
$black: #1e1e24;
$grey-dark: #616165;
$grey: #b7b8ba;
$grey-light: #ececed;
$grey-lighter: #f2f2f3;
$white: #fff;

/* Colors */

/* Primary colors */
$primary-color: var(--primary-color);
$primary-text-color: var(--primary-text-color);
$main-text-color: var(--main-text-color);

/* Secondary colors */
$secondary-color: var(--secondary-color);
$secondary-text-color: var(--secondary-text-color);

/* Background Color */
$background-color: var(--background-color);

/* State Colors */
$active-color: var(--active-color);
$active-text-color: var(--active-text-color);
$disabled-color: #f2f2f3;
$disabled-text-color: #b7b8ba;

/* Share Button Colors */
$download-button-color: var(--download-button-color);
$email-button-color: var(--email-button-color);
$web-share-button-color: var(--web-share-button-color);

/* Error and Success Colors */
$danger-color: var(--danger-color);
$success-color: var(--success-color);

/* Shimmer Gradient Colors */
$shimmer-base-color: var(--shimmer-base-color);
$shimmer-accent-color: var(--shimmer-accent-color);

/* Not mapped on Event Model - TODO: Check if this needs to be moved to database */
$dark-text-color: $black;
$light-text-color: $white;
$warning: #e6a90d;

/* Content Spacing */

/* Extra Extra Small */
$spacing-xxs: 10px;

/* Extra Small */
$spacing-xs: 20px;

/* Small */
$spacing-sm: 30px;

/* Medium */
$spacing-md: 40px;

/* Large */
$spacing-lg: 50px;

/* Extra Large */
$spacing-xl: 70px;

/* generic content padding */
$content-padding: 20px;

/* Box Shadow */
$box-shadow-top: 0 -2px 3px 0 rgba(128, 128, 128, .1);
$box-shadow-bottom: 0 2px 3px 0 rgba(128, 128, 128, .1);

/* Fonts */
$font-size-xs: 15px;
$font-size-sm: 18px;
$font-size-md: 20px;
$font-size-lg: 26px;
$font-size-xl: 30px;
